<template>
  <div class="allPlans" data-anima="top">
    <div class="mb-4">
      <h1>Planos e Preços</h1>
      <p class="text">
        Preços simples. Sem taxas ocultas. Recursos avançados para sua
        plataforma de vídeos
      </p>
    </div>
    <div>
      <b-progress class="plans" :max="max">
        <b-progress-bar :value="value"></b-progress-bar>
      </b-progress>
      <div class="text mt-1">
        {{ value2 }} / {{ value1 }}
      </div>
    </div>
    <div class="mt-4 mb-4 to-plans">
      <Planos :plans="plans" :repeat="3" />
    </div>
  </div>
</template>

<script>
import Planos from "./Planos.vue";
import PlansService from "@/services/resources/PlansService";
const servicePlans = PlansService.build();

export default {
  components: { Planos },
  data() {
    return {
      value: 0,
      value1: 0,
      value2: 0,
      minsize: 15,
      max: 100,
      plans: [],
    };
  },
  mounted() {
    this.getPlans();
    this.getPorcentage();
  },
  methods: {
    getPlans() {
      this.$root.$emit("loadOn");
      servicePlans
        .read("")
        .then((resp) => {
          this.plans = resp;
          //console.log("get plans", resp);
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    getPorcentage() {
      servicePlans
        .read("me")
        .then((resp) => {
          //console.log("get porcentage", resp);
          this.value = parseInt(resp.percentage, 10);
          this.value1 = resp.storage_limit;
          this.value2 = resp.used_storage;
          this.minsize = 15;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .allPlans {
    width: 100% !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
}

.allPlans {
    width: calc(100% - 50px);
    padding-right: 50px;
  .to-plans {
    display: block !important;
    margin: 60px auto !important;
  }
  span.badge.badge-secondary {
    background: #ffffff54 !important;
    padding-top: 7px;
    height: 23px;
  }
  .between {
    justify-content: space-between;
  }
  .text-white {
    color: #fff !important;
  }
  .details {
    svg {
      margin-top: 5px;
      margin-right: 15px;
    }
  }
  // .grid-plans {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(1fr, auto));;
  //   gap: 12px;
  // }
  .plan {
    padding: 30px;
    transition: 0.4s;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .plan:hover {
    border: 1px solid #ededf0;
  }
  .recomended {
    background: var(--maincolor);
  }
  .w100 {
    width: 100% !important;
  }
  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: var(--fontcolor);
  }
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--fontcolor);
  }
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #81858e;
  }
}
</style>
